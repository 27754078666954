import { getCookie, setCookie } from '../utils/helpers';

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize);
} else {
	initialize();
}

/*
* Script to handle the cookie-banner and consents.
*/

var unloads = [];
var consentModule = document.getElementById('consentOverlay');
var consentModuleSimple = document.getElementById('consentBasicContainer');
var consentModuleAdvanced = document.getElementById('consentAdvancedContainer');

function initialize () {
	/*
	* Document has loaded. Handle consent-modal per earlier consents (if any)
	*/
	var hasAnswered = getCookie('_cookie_banner_answered');

	if (hasAnswered) {
		// allow cookies from only the consented checkboxes.
		var analytical = getCookie('_cookie_banner_analytics');
		var marketing = getCookie('_cookie_banner_marketing');
		console.log('initial consent:', {analytical, marketing});
		window.dataLayer.push({
			'event': 'gtm_consent',
			'ad_storage': marketing ? 'granted' : 'denied',
			'analytics_storage': analytical ? 'granted' : 'denied'
		});
	} else {
		// show cookie banner!
		openModal();
		// disable all cookies until given consent
		console.log('initial consent:', {analytical: false, marketing: false});
		window.dataLayer.push({
			'event': 'gtm_consent',
			'ad_storage': 'denied',
			'analytics_storage': 'denied'
		});
	}

	var closeConsentOverlayBtns = document.querySelectorAll('.closeConsentOverlay');
	closeConsentOverlayBtns.forEach((item, i) => {
		item.addEventListener('click', closeModal);
		unloads.push({event: 'click', func: closeModal, elem: item});
	});

	var consentAllowAllBtn = document.querySelector('.consentAllowAll');
	consentAllowAllBtn.addEventListener('click', allowAll);
	unloads.push({event: 'click', func: allowAll, elem: consentAllowAllBtn});

	var consentAllowSelectionBtn = document.querySelector('.consentAllowSelection');
	consentAllowSelectionBtn.addEventListener('click', allowSelection);
	unloads.push({event: 'click', func: allowSelection, elem: consentAllowSelectionBtn});

	var selectConsentTypesBtn = document.getElementById('consentModuleSelectCookies');
	selectConsentTypesBtn.addEventListener('click', openConsentTypesSelection);
	unloads.push({event: 'click', func: openConsentTypesSelection, elem: selectConsentTypesBtn});

	var updateConsentBtn = document.getElementById('updateCookieConsentPreferences');
	if (updateConsentBtn) {
		updateConsentBtn.addEventListener('click', resetConsent);
		unloads.push({event: 'click', func: resetConsent, elem: updateConsentBtn});
	}

	unloads.push({event: 'beforeunload', func: unload, elem: window});
	window.addEventListener('beforeunload', unload);
}

function unload () {
	/*
	* Multiple listeners has been created. Remove them when site closes.
	*/
	unloads.forEach((item, i) => {
		console.log('unloading:', item.event);
		item.elem.removeEventListener(item.event, item.func);
	});
}

function openModal () {
	/*
	* Opens the consent modal.
	*/
	consentModule.classList.add('active');
	consentModuleSimple.classList.add('active');
}

function closeModal () {
	/*
	* Closes the consent modal.
	*/
	consentModule.classList.remove('active');
	consentModuleSimple.classList.remove('active');
	consentModuleAdvanced.classList.remove('active');
}

function openConsentTypesSelection () {
	/*
	* Opens the settings popup, and closes the simple banner.
	*/
	consentModuleSimple.classList.remove('active');
	consentModuleAdvanced.classList.add('active');
}

function allowAll () {
	/*
	* When a user clicks "Allow all", we should set cookies that reflects the choice, and
	* init each scripts that are waiting for consent.
	*/
	console.log('consentAllowAll');
	setCookie('_cookie_banner_answered', 'true', 365);
	setCookie('_cookie_banner_analytics', 'true', 365);
	setCookie('_cookie_banner_marketing', 'true', 365);
	closeModal();
	init3rdPartyScripts(true, true);
}

function allowSelection () {
	/*
	* When a user clicks "Allow" in settings-popup, we should set cookies that reflects the choice, and
	* init each scripts that are waiting for consent.
	*/
	console.log('consentAllowSelection');

	setCookie('_cookie_banner_answered', 'true', 365);

	var allowAnalytics = false;
	var allowMarketing = false;

	var checkBoxes = consentModuleAdvanced.querySelectorAll('[type="checkbox"]'); // get the checkboxes
	checkBoxes.forEach((item, i) => {
		if (item.checked) {
			// checkbox is checked. Only set cookie for checked boxes.
			switch (item.name) {
				case 'analytics':
					console.log('Allow analytics cookies');
					setCookie('_cookie_banner_analytics', 'true', 365);
					allowAnalytics = true;
					break;
				case 'marketing':
					console.log('Allow marketing cookies');
					setCookie('_cookie_banner_marketing', 'true', 365);
					allowMarketing = true;
					break;
				case 'necessary':
					console.log('Necessary / Not uncheckable');
					break;
				default:
					console.warn('Checkbox not known');
			}
		}
	});

	closeModal();
	init3rdPartyScripts(allowAnalytics, allowMarketing);
}

function resetConsent () {
	/*
	* Resets all cookies for the settings, aswell as denies cookies in third-party scripts until
	* new consent. It also opens the consent box so the user can choose again.
	*/
	console.log('resetConsent');
	setCookie('_cookie_banner_answered', '', -1);
	setCookie('_cookie_banner_analytics', '', -1);
	setCookie('_cookie_banner_marketing', '', -1);
	openModal();
	denyCookies();
}

function denyCookies () {
	/*
	* Disables all 3rd-party scripts from setting/using cookies. Using the 3rd-party consent api's.
	*/
	console.log('Disable all cookies');
	window.dataLayer.push({
		'event': 'gtm_consent',
		'ad_storage': 'denied',
		'analytics_storage': 'denied'
	});
}

function init3rdPartyScripts (analytics = false, marketing = false) {
	/*
	* Enables (only the allowed) 3rd-party scripts to set/use their cookies. Using the 3rd-party consent api's.
	*/
	console.log('Init 3rd-party cookies (gtm):', {analytics, marketing});
	window.dataLayer.push({
		'event': 'gtm_consent',
		'ad_storage': marketing ? 'granted' : 'denied',
		'analytics_storage': analytics ? 'granted' : 'denied'
	});
}
